import { Box, Image  } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';


export function Carousel() {
  const imageUrls = [
    {
      url: require("../../assets/images/cover-1.webp"),
      bannerUrl: `/produtos?filter=${encodeURIComponent(JSON.stringify(["Bebidas"]))}`,
      bannerTitle: "Cupom",
      titleColor: "White",
      bannerSubTitle: "FAÇAFESTA",
    },
    {
      url: require("../../assets/images/cover-2.webp"),
      bannerUrl: `/produtos?filter=${encodeURIComponent(JSON.stringify(["Bebidas"]))}`,
      bannerTitle: "FRETE GRÁTIS",
      titleColor: theme.colors.logoOrange,
      bannerSubTitle: "Nas compras acima de R$120,00",
    },
    {
      url: require("../../assets/images/cover-3.webp"),
      bannerUrl: `/produtos?filter=${encodeURIComponent(JSON.stringify(["Bebidas"]))}`,
      bannerTitle: "DESCONTO NA PRIMEIRA COMPRA",
      titleColor: "white",
      bannerSubTitle: "Ganhe agora 5% de desconto",
    }
  ];


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3500, // Set the interval to 3 seconds
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          padding: "10px",
          bottom: '-5%'
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <Box width="100%" height={{base: "28vh", md: "93vh"}}>
      <Slider {...settings}>
        {imageUrls.map((imageUrl, index) => (<>
          <Box
            key={index}
            height={{base: "28vh", md: "93vh"}}
            width={{ base: '100%', md: '100%' }}
            position="relative"
          >
            <Link to={imageUrl.bannerUrl}>
              <LazyLoadImage
                objectFit="cover"
                src={imageUrl.url || require('../../assets/images/placeholder.webp')}
                alt="Boozen"
                effect="blur"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  backgroundPosition: "center",
                }}
                wrapperProps={{
                  style: { transitionDelay: "1s" },
                }}
                loading="lazy"
              />
            </Link>
          </Box>
        </>))}
      </Slider>
    </Box>
  );
}
