function calculateDeliveryPeriod(batchId, product, deliveryMethod, order, orderAgain = null) {
  // if (deliveryMethod === "delivery") {
  //   let date;
  //   if (orderAgain === "orderAgain") {
  //     date = new Date();
  //   } else if (order && order.paid) {
  //     date = new Date(order.updated_at);
  //   } else {
  //     date = new Date();
  //   }

  //   const businessDaysToAdd = (date, days) => {
  //     const result = new Date(date);
  //     while (days > 0) {
  //       result.setDate(result.getDate() + 1);
  //       if (result.getDay() !== 0 && result.getDay() !== 6) {
  //         days--;
  //       }
  //     }
  //     return result;
  //   };
  //   const expirationPlus2BusinessDays = businessDaysToAdd(date, 2);
  //   const expirationPlus8BusinessDays = businessDaysToAdd(date, 8);
  //   return `Prazo de entrega: ${expirationPlus2BusinessDays.toLocaleDateString('pt-BR')} - ${expirationPlus8BusinessDays.toLocaleDateString('pt-BR')}`;
  // } else if (deliveryMethod === "collect") {
  //   let date;
  //   if (orderAgain === "orderAgain") {
  //     date = new Date();
  //   } else if (order && order.paid) {
  //     date = new Date(order.updated_at);
  //   } else {
  //     date = new Date();
  //   }
  //   const businessDaysToAdd = (date, days) => {
  //     const result = new Date(date);
  //     while (days > 0) {
  //       result.setDate(result.getDate() + 1);
  //       if (result.getDay() !== 0 && result.getDay() !== 6) {
  //         days--;
  //       }
  //     }
  //     return result;
  //   };
  //   const expirationPlus8BusinessDays = businessDaysToAdd(date, 8);
  //   return `Disponível para coleta apartir de: ${expirationPlus8BusinessDays.toLocaleDateString('pt-BR')}`;
  // }

  const fixedDate = new Date('2025-01-06'); // Fixed date: January 5, 2025
  const today = new Date();

  if (today < fixedDate) {
    const businessDaysToAdd = (date, days) => {
      const result = new Date(date);
      while (days > 0) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          days--;
        }
      }
      return result;
    };

    // Use fixed date as the starting point for calculations
    const fixedPlus2BusinessDays = businessDaysToAdd(fixedDate, 0);
    const fixedPlus8BusinessDays = businessDaysToAdd(fixedDate, 8);

    if (deliveryMethod === "delivery") {
      return `Prazo de entrega: ${fixedPlus2BusinessDays.toLocaleDateString('pt-BR')} - ${fixedPlus8BusinessDays.toLocaleDateString('pt-BR')}`;
    } else if (deliveryMethod === "collect") {
      return `Disponível para coleta a partir de: ${fixedPlus2BusinessDays.toLocaleDateString('pt-BR')}`;
    }
  }

  // Normal calculation logic after 05/01/2025
  if (deliveryMethod === "delivery") {
    let date;
    if (orderAgain === "orderAgain") {
      date = new Date();
    } else if (order && order.paid) {
      date = new Date(order.updated_at);
    } else {
      date = new Date();
    }

    const businessDaysToAdd = (date, days) => {
      const result = new Date(date);
      while (days > 0) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          days--;
        }
      }
      return result;
    };

    const expirationPlus2BusinessDays = businessDaysToAdd(date, 2);
    const expirationPlus5BusinessDays = businessDaysToAdd(date, 5);
    return `Prazo de entrega: ${expirationPlus2BusinessDays.toLocaleDateString('pt-BR')} - ${expirationPlus5BusinessDays.toLocaleDateString('pt-BR')}`;
  } else if (deliveryMethod === "collect") {
    let date;
    if (orderAgain === "orderAgain") {
      date = new Date();
    } else if (order && order.paid) {
      date = new Date(order.updated_at);
    } else {
      date = new Date();
    }

    const businessDaysToAdd = (date, days) => {
      const result = new Date(date);
      while (days > 0) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          days--;
        }
      }
      return result;
    };

    const expirationPlus8BusinessDays = businessDaysToAdd(date, 8);
    return `Disponível para coleta a partir de: ${expirationPlus8BusinessDays.toLocaleDateString('pt-BR')}`;
  }
}

export { calculateDeliveryPeriod };
