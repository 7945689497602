import {
  Box,
  Button,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th, Td,
  Tbody,
  FormControl,
  FormLabel,
  Select,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import { Logistics } from "../Logistics";
import { theme } from "../../../styles/theme";



export function Sales() {
  const { user } = useAuth();
  const [allOrders, setAllOrders] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [batches, setBatches] = useState([]);
  const [products, setProducts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [showPaidOrders, setShowPaidOrders] = useState(false);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const yearParam = parseInt(selectedYear, 10);
    const monthParam = parseInt(selectedMonth, 10);
    handleFetchAllPaidOrders(yearParam, monthParam);
  };

  async function handleFetchAllPaidOrders(year, month) {
    const apiUrl = `api/v1/batch_orders?year=${year}&month=${month}`;
    try {
      const response = await api.get(apiUrl);
      setAllOrders(response.data.batch_orders);
      setBatches(response.data.batches);
      setProducts(response.data.products);
      setPayments(response.data.payments);
    } catch (error) {
      console.error("Error fetching batch orders:", error);
    }
  }

  async function handleFetchAllUsers() {
    const response = await api.get("api/v1/users")
    setAllUsers(response.data)
  }

  useEffect(() => {
    handleFetchAllPaidOrders();
    handleFetchAllUsers();
  }, [])

  const translateWord = (word) => {
    const languageMap = {
      "pending" : "Pendente",
      "out_for_delivery" : "Saiu Para Entrega",
      "delivered" : "Entregue",
      "collected" : "Coletado",
      "cancelled" : "Cancelado"

    };
    return languageMap[word]
  }

  const generateYearOptions = (minYear, maxYear) => {
    const options = [];
    for (let year = maxYear; year >= minYear; year--) {
      options.push(year.toString());
    }
    return options;
  };

  function formatDateDifference() {
    const currentDateObject = new Date();
    const createdDateObject = new Date(
      currentDateObject.getFullYear(),
      currentDateObject.getMonth()
    );

    const monthNames = [
      "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
      "Jul", "Ago", "Set", "Out", "Nov", "Dez"
    ];

    const monthCreated = monthNames[createdDateObject.getUTCMonth()];
    const yearCreated = createdDateObject.getUTCFullYear().toString().substring(2);
    const monthCurrent = monthNames[currentDateObject.getUTCMonth()];
    const yearCurrent = currentDateObject.getUTCFullYear().toString().substring(2);

    const formattedCreatedDate = `${monthCreated} ${yearCreated}`;
    const formattedCurrentDate = `${monthCurrent} ${yearCurrent}`;

    return (
      <Text>{(selectedMonth && selectedYear) ? `${selectedMonth} - ${selectedYear}` : formattedCurrentDate}</Text>
    );
  }

  const handleOrdersToShow = async (e) => {
    e.preventDefault()
    setShowPaidOrders(!showPaidOrders)
    if (showPaidOrders) {
      setAllOrders(allOrders.filter(e => e.paid === true))
    } else {
      await handleFetchAllPaidOrders(parseInt(selectedYear, 10), parseInt(selectedMonth, 10));
    }
  }

  const updateOrderStatus = (orderId, newStatus) => {
    const updatedOrders = allOrders.map((order) =>
      order.id === orderId ? { ...order, delivery_status: newStatus } : order
    );
    setAllOrders(updatedOrders);
  };


  const minYear = 2023;
  const maxYear = new Date().getFullYear();

  const displayPaymentStatusAndDate = (order) => {
    const payment = payments.find(payment => payment.id === order.payment_id);
    console.log(">>>>", order)
    let date = new Date(payment?.invoice_paid_at)

    let paymentMethod = payments.find(e => e.id === order.payment_id)?.payment_method


    return (
      <Td>
        {order.paid ? (
          payment ? (
            <span>Pago ({date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()} | {date.getHours()}:{date.getMinutes()}) | {paymentMethod === "pix" ? "Pix" : "Cartão"}</span>
          ) : (
            <span>Data de pagamento inválida</span>
          )
        ) : (
          <span>Em Aberto</span>
        )}
      </Td>
    );
  }

  return (
    <Box>
      <Text textAlign="start" fontSize="26px">
        Relatório de vendas
      </Text>
      <Box display="flex" justifyContent="space-between" gap="20px">
        <Box display={{base: 'block' , md: 'flex'}} justifyContent='space-around'  alignItems="center" width={{base: '75%' , md: '75%'}}  borderRadius='15px' padding='10px 50px' boxShadow='rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Box display='flex' gap='5px' alignItems='end' justifyContent='start'>
              <FormControl width='50%'>
                <FormLabel mb="0px">Mês</FormLabel>
                <Select value={selectedMonth} onChange={handleMonthChange} height="30px">
                  <option value="">Escolha mês referência</option>
                  <option value="01">Janeiro</option>
                  <option value="02">Fevereiro</option>
                  <option value="03">Março</option>
                  <option value="04">Abril</option>
                  <option value="05">Maio</option>
                  <option value="06">Junho</option>
                  <option value="07">Julho</option>
                  <option value="08">Agosto</option>
                  <option value="09">Setembro</option>
                  <option value="10">Outubro</option>
                  <option value="11">Novembro</option>
                  <option value="12">Dezembro</option>
                </Select>
              </FormControl>
              <FormControl width='50%'>
                <FormLabel mb="0px">Ano</FormLabel>
                <Select value={selectedYear} onChange={handleYearChange} height="30px">
                  <option value="">Escolha ano referência</option>
                  {generateYearOptions(minYear, maxYear).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Button type="submit"
                background='green'
                width="25%"
                size="sm"
                bg={theme.colors.secondaryGreen}
                color="white"
                borderRadius="20px"
                border={`1px solid ${theme.colors.secondaryGreen}`}
                _hover={{
                  background: "transparent",
                  color: `${theme.colors.secondaryGreen}`,
                  borderCorlor: `${theme.colors.secondaryGreen}`
                }}
              >Filtrar</Button>
            </Box>
          </form>
          <Button onClick={(e) => handleOrdersToShow(e)}
            border={`1px solid ${theme.colors.secondaryGreen}`}
            color={theme.colors.secondaryGreen}
            borderRadius="20px"
            size="sm"
            mt="3%"
            _hover={{
              transform: "scale(1.02)",
              color: "white",
              bg: `${theme.colors.secondaryGreen}`,
            }}
            style={{
              backgroundColor: showPaidOrders ? `${theme.colors.secondaryGreen}` : "transparent",
              color: showPaidOrders ? "white" : `${theme.colors.secondaryGreen}`,
            }}

          >
            {showPaidOrders ? "Mostrar todos os pedidos" : "Mostrar pedidos pagos"}
          </Button>
        </Box>

        <Box display={{base: 'block' , md: 'flex'}} justifyContent='space-around' width={{base: '100%' , md: '50%'}} gap='1rem' >
          <Box flex='1' borderRadius='15px' padding='10px 50px' boxShadow='rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'>
            <Stat>
              <StatLabel> Pedidos pagos: {allOrders.filter(element => element.paid === true ).length}</StatLabel>
              <StatNumber>{allOrders.filter(element => element.paid === true ).reduce((total, order) => total + order.amount, 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</StatNumber>
              <StatHelpText>{formatDateDifference()}</StatHelpText>
            </Stat>
          </Box>
          <Box flex='1' borderRadius='15px' padding='10px 50px' boxShadow='rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset' marginTop={{base: '20px', md: '0px'}}>
            <Stat>
              <StatLabel>Pedidos em aberto: {allOrders.filter(element => element.paid === false ).length}</StatLabel>
              <StatNumber> {allOrders.filter(element => element.paid === false ).reduce((total, order) => total + order.amount, 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</StatNumber>
              <StatHelpText>{formatDateDifference()}</StatHelpText>
            </Stat>
          </Box>
        </Box>
      </Box>



      <TableContainer marginTop="100px">
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>#Id</Th>
              <Th>Valor</Th>
              <Th>Pagamento</Th>
              <Th>Quantidade</Th>
              <Th>Pagamento(#id)</Th>
              <Th>Cliente</Th>
              <Th>Produto</Th>
              <Th>Logística</Th>
              <Th>Status</Th>

            </Tr>
          </Thead>
          <Tbody>
            {allOrders?.map((order) => {
              return (
                <Tr
                  key={order.id}
                  background={
                    order.delivery_status === 'cancelled'
                      ? 'gray.100'
                      : order.paid
                      ? 'green.100' // Change this to the color you desire for cancelled orders
                      : 'red.100'
                  }
                >
                  <Td>{order.id}</Td>
                  <Td>{order.amount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Td>
                  {displayPaymentStatusAndDate(order)}
                  <Td>{order.quantity} - {batches.find(element => element.id === order.batch_id)?.unity_of_measurement}</Td>
                  <Td>{order.payment_id}</Td>
                  <Td>{allUsers.find(element => element.id === order.user_id)?.full_name}</Td>
                  <Td>{products.find(element => element.id === batches.find(element => element.id === order.batch_id)?.product_id)?.name}</Td>

                  {order.delivery_method === "delivery" && (<>
                    <Td>Entrega</Td>
                    {order.paid ? (
                      <>
                        {order.delivery_status === 'pending' && (
                          <Td fontWeight='bold' color='red'>{translateWord(order.delivery_status)}</Td>
                        )}
                        {order.delivery_status === 'out_for_delivery' && (
                          <Td fontWeight='bold' color='blue'>{translateWord(order.delivery_status)}</Td>
                        )}
                        {order.delivery_status === 'delivered' && (
                          <Td fontWeight='bold' color='green'>{translateWord(order.delivery_status)}</Td>
                        )}
                        {order.delivery_status === 'cancelled' && (
                          <Td fontWeight='bold' color='red'>{translateWord(order.delivery_status)}</Td>
                        )}
                      </>
                    ) : (
                      <Td fontWeight='bold'>Aguardando pagamento</Td>
                    )}
                  </>)}
                  {order.delivery_method === "collect" && (<>
                    <Td>Coleta</Td>
                    {order.paid ?
                      (
                        <Td fontWeight='bold' color={order.delivery_status === 'collected' ? 'green' : 'red'}>{translateWord(order.delivery_status)}</Td>
                      )
                      :
                      (
                        <Td fontWeight='bold'>Aguardando pagamento</Td>
                      )
                    }
                  </>)}
                  <Td>
                    <Logistics
                      orderId={order.id}
                      updateOrderStatus={updateOrderStatus}
                      deliveryStatus={order.delivery_status}
                    />
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
